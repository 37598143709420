import {createMuiTheme} from '@material-ui/core'


const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: '1.5rem',
      },
    },
    MuiDialog: {
      paper: {
        [defaultTheme.breakpoints.down('sm')]: {
          margin: 0,
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        },
      },
      paperScrollPaper: {
        [defaultTheme.breakpoints.down('sm')]: {
          maxHeight: '100%',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '1rem 1.5rem',
        [defaultTheme.breakpoints.up('sm')]: {
          padding: '1rem 2rem',
        },
      },
    },
    MuiDialogContent: {
      root: {
        overflowX: 'hidden',
        padding: '1rem 1.5rem',
        [defaultTheme.breakpoints.up('sm')]: {
          padding: '1rem 2rem',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '1rem 1.5rem',
        justifyContent: 'space-between',
      },
    },
    MuiGrid: {
      item: {
        '&:last-child': {
          marginRight: 'auto',
        },
      },
    },
    MuiCard: {
      root: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    MuiCardHeader: {
      action: {
        alignSelf: 'center',
        width: '2.5rem',
        height: '2.5rem',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 'inherit',
        color: 'inherit',
      },
    },
    MuiCardActionArea: {
      root: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'flex-start',
        flexGrow: 1,
        height: '100%',
      },
      focusHighlight: {
        display: 'none',
      },
    },
    MuiButton: {
      outlined: {
        'fontWeight': 'bold',
        'fontSize': '0.8rem',
        'padding': '0.6rem 1rem',
        'borderColor': '#262E2C',
        'backgroundColor': 'transparent',
        'color': '#262E2C',
        '&:hover': {
          backgroundColor: '#262E2C',
          borderColor: '#262E2C',
          color: 'white',
        },
        '&:not(:first-child)': {
          marginLeft: '0.5rem',
        },
      },
    },
    MuiChip: {
      outlined: {
        borderColor: 'rgba(0, 0, 0, 0.54)',
        marginRight: '0.5rem',
        marginBottom: '0.5rem',
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.8rem',
        fontWeight: '500',
        cursor: ({isIndex}) => isIndex && 'pointer',
      },
    },
  },
})

export default theme
