import './src/styles/global.scss'

export {wrapRootElement} from './src/gatsby-theme-material-ui-top-layout/themeProvider'

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Táto aplikácia má novú verziu. ` +
      `Želáte si ju zobraziť?`
  )
  if (answer === true) {
    window.location.reload()
  }
}
